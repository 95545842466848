import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEnglish from "../assets/locales/en/special.json";
import translationSpanish from "../assets/locales/es/special.json";

const resources = {
    en: {
        special: translationEnglish,
    },
    es: {
        special: translationSpanish,
    },
}

i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources,
        debug: true,
        fallbackLng: "en",
        ns: ['special'],
    });


export default i18next;
