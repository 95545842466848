import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/index.scss';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import {JsonRpcContextProvider} from "./contexts/JsonRpcContext";
import {WalletConnectProvider} from "./contexts/walletConnect";
import {ErrorPage} from "./pages/error";
import {Landing} from "./pages/landing";
import "./utils/i18utils";


const Main = React.lazy(() =>
    import("./pages").then((module) => ({
        default: module.Main,
    }))
);

//FIXME needed ?
//i18next.use();

ReactDOM.render(
  <Provider store={store}>
    <WalletConnectProvider>
      <JsonRpcContextProvider>
          <Suspense fallback={Landing}>
          {/*<I18nextProvider i18n={i18next}>*/}
          <Router>
              <Switch>
                  {/*<Route path={'/pay'} component={Pay}></Route>*/}
                  <Route path={'/error'} component={ErrorPage}></Route>
                  {/*<Route path={'/merchant/register'} component={RegisterMerchant}></Route>*/}
                  <Route path={'/'} component={Main} ></Route>
              </Switch>
            <ToastContainer
              toastClassName="w-full m-w-45 flex bg-black bg-opacity-90 border-2 border-secondary rounded-16xl shadow-md p-4 text-sm"
              bodyClassName={() => 'text-sm font-white font-med block p-1'}
              autoClose={false}
              icon={true}
              position="bottom-center"
              hideProgressBar={true}
              closeOnClick={false}
            />
          </Router>
          {/*</I18nextProvider>*/}
          </Suspense>
        </JsonRpcContextProvider>
    </WalletConnectProvider>
  </Provider>,
  document.getElementById('root')
);

reportWebVitals();
